'use client'

import { useRef, useEffect, useState, useMemo } from 'react'
import { usePathname, useRouter } from 'next/navigation'
import useSWR from 'swr'

import { useConfigsContext } from '@/context/ConfigsContext'
import { useAuthContext } from '@/context/AuthContext'
import { useVSCSdkContext } from '@/context/VSCSdkContext'
import { cn } from '@/lib/utils'
import { createShareComponentProfile } from '@/lib/auth'
import ViverseCommunityDialog from '@/components/ViverseCommunityDialog'
import { getPointBalance } from '@/services/community'

const STATIC_HEADER_PAGES = ['/profile', '/search']

function Header() {
  const headerRef = useRef<HTMLDivElement>(null)
  const [openCommunityDialog, setOpenCommunityDialog] = useState(false)

  const router = useRouter()
  const pathname = usePathname()
  const { store } = useConfigsContext()
  const { isSignedIn, handleLogin, handleLogout } = useAuthContext()
  const { viverseShareComponent, isMountedHeader, setIsMountedHeader, changeLocale } =
    useVSCSdkContext()

  const profile = store?.profile
  const htcToken = store?.credentials?.htcToken
  const communityToggle = store?.communityToggle

  const { data } = useSWR(!htcToken ? null : 'pointBalance', () =>
    getPointBalance(htcToken!).then((res) => res?.results?.data),
  )
  const pointBalance = data?.pointBalance || 0

  const shareComponentProfile = useMemo(() => {
    return isSignedIn ? createShareComponentProfile(profile!, pointBalance) : {}
  }, [isSignedIn, profile, pointBalance])

  useEffect(() => {
    type ChangeRouteArgs = {
      origin: string
      path: string
    }

    function handleCreateWorld() {
      router.push('/profile?create')
    }
    function handleChangeRoute(event: CustomEvent<ChangeRouteArgs>) {
      const origin = event.detail.origin
      const path = event.detail.path
      if (window.location.origin === origin) {
        router.push(path)
      } else {
        window.location.href = `${origin}${path}`
      }
    }

    // 🌎 handle CustomEvent from share-component
    document.addEventListener('createWorld', handleCreateWorld)
    document.addEventListener('onChangeRoute', handleChangeRoute as EventListener)
    return () => {
      document.removeEventListener('createWorld', handleCreateWorld)
      document.removeEventListener('createWorld', handleChangeRoute as EventListener)
    }
  }, [router])

  useEffect(() => {
    if (isMountedHeader && viverseShareComponent) {
      viverseShareComponent.updateProfile(shareComponentProfile)
    }
  }, [isMountedHeader, viverseShareComponent, isSignedIn, shareComponentProfile])

  useEffect(() => {
    if (viverseShareComponent && headerRef.current) {
      viverseShareComponent.mountHeader({
        $header: headerRef.current,
        localeConfig: {
          changeLocale,
        },
        authConfig: {
          onLogin: handleLogin,
          onLogout: handleLogout,
          changeRoute: (origin: string, path: string) => {
            // ❗️ currently support only location.href to change route instead of router.push
            // ❗️ due to closing common control panel in mobile issue
            window.location.href = `${origin}${path}`
          },
          profile: shareComponentProfile,
        },
        layoutConfig: {
          type: 4,
          community: {
            isChinaUser: !communityToggle,
            showIframe: () => setOpenCommunityDialog(true),
          },
        },
      })

      setIsMountedHeader(true)
    }
  }, [
    handleLogin,
    handleLogout,
    setIsMountedHeader,
    shareComponentProfile,
    viverseShareComponent,
    communityToggle,
    changeLocale,
  ])

  return (
    <>
      <header
        ref={headerRef}
        className={cn('h-[56px] w-full bg-transparent transition-all lg:h-[80px]', {
          'fixed top-0 z-50': pathname !== '/profile',
          static: STATIC_HEADER_PAGES.includes(pathname),
        })}
      />
      <ViverseCommunityDialog open={openCommunityDialog} onOpenChange={setOpenCommunityDialog} />
    </>
  )
}

export default Header
